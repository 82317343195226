import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useRef} from "react"
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"
import ReactPlayer from "react-player"
import "react-multi-carousel/lib/styles.css"

import { Link,useParams, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import awicon from "../../assets/images/services-icon/coin.png"
import duration from "../../assets/images/services-icon/duration.png"
import Select from "react-select"
// import images

import fr1 from "../../assets/images/services-icon/fr1.png"
import dsmile from "../../assets/images/services-icon/dog_smile.png"
import BackwardIcon from "../../assets/images/services-icon/backward.png"
import PauseIcon from "../../assets/images/services-icon/pause.png"

//i18n
import { withTranslation } from "react-i18next"
import { sample } from "lodash"
// import Duration from './Duration';

import axios from "axios";
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import Loader from "react-spinner-loader";
import { SECURITY_KEY, BASE_URL } from "../../global";

const Task = props => {

  const { key } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type') || null;
  const homework = searchParams.get('homework') || null;



  let pk_activity_key = null;
  let IsType = null;
  let IsHomework = null;
  if(key && key.length >= 16 && type && type!=null && homework && homework!=null){
    pk_activity_key = key;
    IsType = type;
    IsHomework = homework;
  }else{
    window.history.back()
  }






  const [loader, setLoader] = useState(false);
  const [ActivityData, setActivityData] = useState([]);


  
//Timmere start

const [seconds, setSeconds] = useState(0);
const [isRunning, setIsRunning] = useState(false);

useEffect(() => {
  let timer;
  if (isRunning) {
    timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);
  }

  return () => clearInterval(timer);
}, [isRunning]);

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const remainingSeconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const handleToggleTimer = () => {
  setIsRunning((prevState) => !prevState);
};

const handleResetTimer = () => {
  setSeconds(0);
  setIsRunning(true);
};


//Timer end
//task



const [Completed, setCompleted] = useState(false);


const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;
const navigate = useNavigate();
  useEffect(() => {
    

    fetchData();

    
  }, [location])


  const fetchAttendanceData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        pk_activity_key &&
        pk_activity_key != null &&
        pk_activity_key !=''
      ) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios.post(BASE_URL+"studentSession/StudentAttendance", {
            pk_activity_key: pk_activity_key
          })
          .then(responseAttendance => {
            

          })
          .catch(error => {
            
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
         
         window.history.back()
      }
    } else {
      
      navigate("/login")
    }
  }

  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        pk_activity_key &&
        pk_activity_key != null &&
        pk_activity_key !=''
      ) {
        setLoader(true);
        setActivityData([]);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL+"studentSession/StudentAssessmentQuestions", {
            pk_activity_key: pk_activity_key,
            subject_id : type,
          })
          .then(responseActivity => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            setLoader(false);

            
    
           
            if (
              responseActivity.data.LessonActivitiesQuestions.length > 0
            ) {
              


              responseActivity.data.LessonActivitiesQuestions.forEach((activityQuestion, index2) => {

                  
                  if (activityQuestion.question && activityQuestion.question.length > 0) {
                    let answerLength = activityQuestion.question[0].StudentAnsAttempts.length;
                      
                      if(parseInt(IsHomework) === 1){
                        if (answerLength <= 0) {
                          handleResetTimer()
                          //console.log('attented');
                        }
                      }else{
                        if(answerLength <= 0){
                          fetchAttendanceData();
                          handleResetTimer();
                        // console.log('attented');
                        }
                       
                      }

                    }else{
                      toast.error("Question data not found", {
                        autoClose: 3000, // 3 seconds
                        onClose: () => {
                          history.back(); // Navigate back in history after toast is closed
                        }
                      });
                    }
                  });
                  setActivityData(responseActivity.data);
            } else {
              toast.error("Question data not found", {
                autoClose: 3000, // 3 seconds
                onClose: () => {
                  history.back(); // Navigate back in history after toast is closed
                }
              });
              setActivityData([]);
             
            }
           

          })
          .catch(error => {
             setLoader(false);
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
         setLoader(false);
         window.history.back()
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }



  //task



  
  const [ResonTextData, setResonText] = useState('');
    const [modal_standard, setmodal_standard] = useState(false);

    const [isFlagReport, setFlagReport] = useState(false);
    
    const [selectedGroup, setselectedGroup] = useState(null);

    const [selectQuestion, setselectQuestion] = useState(null);
    
    function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
    }
    
    const optionGroup = [
    // {
    // label: "Select Subject",
    // options: [
    { label: "Spelling error / Typo", value: "Spelling error / Typo" },
    { label: "The solution is wrong", value: "The solution is wrong" },
    {
    label: "The question is not relevant to the topic",
    value: "The question is not relevant to the topic",
    },
    {
    label: "The question cannot be solved",
    value: "The question cannot be solved",
    },
    {
    label: "The question is incomplete",
    value: "The question is incomplete",
    },
    { label: "Others", value: "Others" },
    // ],
    // },
    ]

function textareachange(event) {
  const text = event.target.value;
 // if (count > 0) {
  setResonText(text);
 // }
  //settextcount(event.target.value.length)
  }



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reson: "",
      reson_text: "" 
    },
    validationSchema: Yup.object({
      reson: Yup.mixed().required("Select a reason is required."),
      reson_text: Yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Reason is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),        
    }),
    onSubmit: async values => {
      // console.log(values);

      if (authUser && authUser != "" && authUser != null) {

        setFlagReport(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
          axios.post(BASE_URL+'studentSession/flagReport', {
            fk_lesson_activity_key: selectQuestion.fk_lesson_activity_key,
            fk_lesson_activity_question_key : selectQuestion.pk_lesson_activity_question_key,
            fk_sub_id: selectQuestion.fk_sub_id,
            question_id: selectQuestion.fk_question_id,
            // reson_text: ResonTextData,
            // reson: selectedGroup
            ...values
          }).then(responseFlag => {
            //console.log(responseSessionEnd.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseFlag.data) === 1) {
              tog_standard();
              setFlagReport(false);
              toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
                autoClose: 3000, // 3 seconds in this example
              });
            } else if(parseInt(responseFlag.data) === 2){
              tog_standard();
              setFlagReport(false);
              toast.error("Already submitted a report on this question", {
                autoClose: 3000, // 3 seconds in this example
              });
            } else {
              tog_standard();
              setFlagReport(false);
              toast.error("Failure! Unable to insert data. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              });
            }
  
  
  
          })
            .catch(error => {
              //console.log(error)
              tog_standard();
              setFlagReport(false);
              if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
                localStorage.clear();
                navigate("/login");
              }
            });
  
      
      }
      else {
        setFlagReport(false);
        navigate("/login");
      }

      //dispatch(loginUser(values, props.router.navigate));
    },
  })
  const tog_standard = (data) => {
    //console.log(data);
    setmodal_standard(!modal_standard);
    setselectedGroup(null);
    validation.resetForm()
    setResonText('');
    setselectQuestion(data ? data : null);
    }



    // function submitFlagReport(){
    //   if (authUser && authUser != "" && authUser != null) {

    //     setFlagReport(true);
    //       axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    //       axios.post(BASE_URL+'studentSession/flagReport', {
    //         fk_lesson_activity_key: selectQuestion.fk_lesson_activity_key,
    //         fk_lesson_activity_question_key : selectQuestion.pk_lesson_activity_question_key,
    //         fk_sub_id: selectQuestion.fk_sub_id,
    //         question_id: selectQuestion.fk_question_id,
    //         reson_text: ResonTextData,
    //         reson: selectedGroup
    //       }).then(responseFlag => {
    //         //console.log(responseSessionEnd.data);
    //         // Assuming the API response is an array of objects with 'id' and 'value' properties
    //         if (parseInt(responseFlag.data) === 1) {
    //           tog_standard();
    //           setFlagReport(false);
    //           toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
    //             autoClose: 3000, // 3 seconds in this example
    //           });
    //         } else {
    //           tog_standard();
    //           setFlagReport(false);
    //           toast.error("Failure! Unable to insert data. Try again later", {
    //             autoClose: 3000, // 3 seconds in this example
    //           });
    //         }
  
  
  
    //       })
    //         .catch(error => {
    //           //console.log(error)
    //           tog_standard();
    //           setFlagReport(false);
    //           if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
    //             localStorage.clear();
    //             navigate("/login");
    //           }
    //         });
  
      
    //   }
    //   else {
    //     setFlagReport(false);
    //     navigate("/login");
    //   }
  

    // }

    const [isTaskSubmit, setTaskSubmit] = useState(false);

    function submitTaskAnswer(){
      if (authUser && authUser != "" && authUser != null) {

        setTaskSubmit(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
          axios.post(BASE_URL+'studentAnswer/submitQuestionTask', {
            activity_type: ActivityData.activity_type,
            fk_lesson_key: ActivityData.fk_lesson_key,
            pk_lesson_activity_key : ActivityData.pk_lesson_activity_key,
            fk_sub_id: ActivityData.fk_sub_id,
            question_id: ActivityData.LessonActivitiesQuestions[0].fk_question_id,
            submitted_on: seconds,
            fk_ses_key: ActivityData.fk_sest_key
          }).then(responseVideo => {
            //console.log(responseSessionEnd.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseVideo.data) === 1) {
              setTaskSubmit(false);
              fetchData();
            } else {
              setTaskSubmit(false);
              fetchData();
            }
  
  
  
          })
            .catch(error => {
              //console.log(error)
              
              setTaskSubmit(false);
              if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
                localStorage.clear();
                navigate("/login");
              }
            });
  
      
      }
      else {
        setFlagReport(false);
        navigate("/login");
      }
  

    }


    // console.log(ActivityData);
    // console.log(selectQuestion);
  return (
    <React.Fragment>
       <Loader
      show={loader}
      type="body"
    // stack="vertical"
    // message="Loading Data"
    />
    <ToastContainer autoClose={3000} />

    <Modal isOpen={modal_standard} toggle={tog_standard}>
    <Form
                        className="mt-4"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action="#"
                      >
                        <ModalHeader toggle={tog_standard}>
                          Flag this Question
                        </ModalHeader>
                        <ModalBody>
                          <div className="flex-grow-1 mb-3 cs-rsm">
                            <Label>Select a Reason</Label>
                            <Select  name="reson" id="reson" className="cs-style bg-mutant" value={optionGroup.find(option =>
                                option.value &&
                                validation.values.reson &&
                                option.value.toString() == validation.values.reson.toString()
                              )} 
                              placeholder="Select"
                              onChange={(selectedOption) => {
                                const event = {
                                    target: {
                                        name: "reson",
                                        value: selectedOption["value"],
                                    },
                                };
                                validation.handleChange(event);
                            }}
                            onBlur={(event) => {
                              validation.handleBlur(event);
                            }}
                            // invalid={
                            //   validation.touched.reson &&
                            //   validation.errors.reson
                            //     ? true
                            //     : false
                            // }
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              />
                               {validation.touched.reson &&
                                validation.errors.reson ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson}</div>
                                ) : null}
                          </div>
                          <div className="flex-grow-1 cs-rsm">
                            <Label>Reason:</Label>
                            <Input style={{ height: "200px" }} type="textarea" name="reson_text" id="reson_text" 
                            // onChange={e=> {
                            // textareachange(e)
                            // }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reson_text || ""}
                            // invalid={
                            //   validation.touched.reson_text &&
                            //   validation.errors.reson_text
                            //     ? true
                            //     : false
                            // }
                            maxLength="225"
                            rows="3"
                            placeholder="Write your reason here..."
                            />
                            {validation.touched.reson_text &&
                                validation.errors.reson_text ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson_text}</div>
                                ) : null}
                          </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                          <Button type="submit" className="btn btn-danger cs-btn-danger" disabled={isFlagReport}>
                            Submit
                          </Button>
                        </ModalFooter>
                        </Form>
                      </Modal>
      <div className="page-wrapper">
        <div className="cs-pg-header bg-dark-blue py-4">
          <Container className="custom-wt">
            <div className="d-flex align-items-center">
              <div className="mt-0 border-right me-4" style={{ width: "20%" }}>
              {parseInt(IsHomework) === 1 ? 
            <Link className="text-white font-size-14 text-uppercase"  to={"/homework-list"}> {" "}
            <span>
              <i className=" fas fa-chevron-left me-1"></i>
            </span>{" "}
            BACK TO HOMEWORK
            </Link>
            :
            <div className="text-white font-size-14 text-uppercase"  to={"#"} onClick={() => history.back()}> {" "}
            <span>
              <i className=" fas fa-chevron-left me-1"></i>
            </span>{" "}
            Back
            </div>
          }
              </div>
              <div className="d-flex align-items-center justify-content-between flex-grow-1">
                <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">
                {ActivityData && ActivityData.activity_node && ActivityData.activity_node.length > 0 && ActivityData.activity_node[0].name ? ActivityData.activity_node[0].name : null}
                </h6>
                <p className="mb-0 text-uppercase luminous-wht">
                  {" "}
                  <span className="text-yellow me-1">{ActivityData && ActivityData.subject_name ? ActivityData.subject_name : null}</span> Task{" "}
                </p>
                <div className="luminous-wht font-size-16">
                  <img width={24} className="" src={awicon} alt="" /> {ActivityData && ActivityData.coins_earned ? ActivityData.coins_earned : 0}/{ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ? ActivityData.LessonActivitiesQuestions.length * 2 : 0}
                  coins earned
                </div>
              </div>
            </div>
          </Container>
        </div>
        {ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ?
            
            ActivityData.LessonActivitiesQuestions.map(
             (questionActivity, activeindex) => {
              if (
                questionActivity.question &&
                questionActivity.question.length > 0 &&
               (parseInt(questionActivity.fk_sub_id) === 1 ||  parseInt(questionActivity.fk_sub_id) === 2)
              ) {
               
                return questionActivity.question.map(
                
                (InnerActivityData, index) => {
                  if (index === 0) {
                    return(
                      <Container className="custom-wt">
          <div className="assessement-section summary-width-hold mt-4">
            <div className="hw-list mt-4 pb-100">
              <ul className="list-unstyled mb-0">
                <li className="justify-content-between">
                  <div className="d-flex align-items-start gap-4 justify-content-end">
                    <div className="mb-0 d-flex align-items-center gap-2">
                      <p className="mb-0">#{InnerActivityData.pk_tutor_library_item_id ? InnerActivityData.pk_tutor_library_item_id : null }</p>
                      <span 
                      // onClick={()=> tog_standard(questionActivity)}
                      >
                        <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                      </span>
                    
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                    {/* <h4 className="font-size-16 fw-semibold mb-4 mt-4">
                      Please watch the entire video to get the coins.
                    </h4> */}
                    {InnerActivityData.StudentAnsAttempts.length <= 0 ?
                      <Button className="btn btn-danger cs-btn-danger mt-1 text-uppercase"  disabled={isTaskSubmit} onClick={()=> submitTaskAnswer()}>
                        Complete Activity {parseInt(ActivityData.tut_clock_is_active) != 1 ? formatTime(seconds) : ''}
                      </Button> : <p></p>}
                  </div>
                  <div className="video-wrapper">
                   <h4>{InnerActivityData.name ? InnerActivityData.name : 'No task'}</h4>
                  </div>

                 
                </li>
                    {/* <div>
                    <span>Duration: {formatDuration(duration)}</span>
          <br />
          <span>Elapsed: {formatDuration(duration * played)}</span>
          <br />
          <span>Remaining: {remainingTime}</span>
                    </div> */}


              </ul>
            </div>

            { InnerActivityData.StudentAnsAttempts.length > 0 ? <div className="navigator-wrapper position-relative mt-5">
                  <div className="nv-dg-smile">
                    <img width={400} src={dsmile} alt="" />
                  </div>
                  <div className="navigator-nxt">
                    {InnerActivityData.duration && InnerActivityData.duration!=''? 
                    <p className="opacity-75">
                      SUGGESTED TIME: {InnerActivityData.duration? InnerActivityData.duration : null}
                    </p>
                  : null}
                    <p className="font-size-16 d-flex align-items-center gap-2">
                      <img width={24} src={awicon} /> {ActivityData && ActivityData.coins_earned ? ActivityData.coins_earned : 0}/{ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ? ActivityData.LessonActivitiesQuestions.length * 2 : 0} coins earned
                    </p>

                    {parseInt(IsHomework) === 1 ? 
                     
                      <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => history.back()}>
                       BACK TO HOMEWORK
                      </Button>
                      :
                      <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => history.back()}>
                       BACK TO LESSON PLAN
                    </Button>
                      
                    }
                      
                    
                    
                    
                  </div>
                </div>
                : null }
          </div>
        </Container>
                    );

                  }
                });
              }
            })

            : null
          }
                   
        
      </div>
    </React.Fragment>
  )
}

Task.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Task)
