
import React, { useState,useEffect,useCallback, useRef } from "react"
import {
Container,
Button,
Modal,
ModalBody,
ModalHeader,
ModalFooter,
Nav,
NavItem,
NavLink,
TabContent,
TabPane,
Label,
Input,
Form,
FormFeedback,
} from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link,useParams, useLocation, useNavigate  } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../../assets/images/services-icon/coin.png"
import Select from "react-select"


import dsmile from "../../../assets/images/services-icon/dog_smile.png"

//i18n
import axios from "axios";
// import { BASE_URL } from "../../global";

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import Loader from "react-spinner-loader";

import { SECURITY_KEY, BASE_URL } from "../../../global";

import fr1 from "../../../assets/images/services-icon/fr1.png"
import ts1 from "../../../assets/images/services-icon/ts1.png"
import ts2 from "../../../assets/images/services-icon/ts2.png"
import ts3 from "../../../assets/images/services-icon/ts3.png"
import ts4 from "../../../assets/images/services-icon/ts4.png"
//emoje inactive
import em1 from "../../../assets/images/services-icon/em1.svg"
import em2 from "../../../assets/images/services-icon/em2.svg"
import em3 from "../../../assets/images/services-icon/em3.svg"
import em4 from "../../../assets/images/services-icon/em4.svg"
import em5 from "../../../assets/images/services-icon/em5.svg"

//emoje active
import ema1 from "../../../assets/images/services-icon/ema1.svg"
import ema2 from "../../../assets/images/services-icon/ema2.svg"
import ema3 from "../../../assets/images/services-icon/ema3.svg"
import ema4 from "../../../assets/images/services-icon/ema4.svg"
import ema5 from "../../../assets/images/services-icon/ema5.svg"

const Comprehension = React.memo(({triggerFunction, data, part, triggerSubmitFunction, responceData ,key}) => {
  //const ActivityData1 = data;
  //const ActivePartData = part;
    // const [ActivePartData, setActivePartData] = useState([]);
    // useEffect(() => {
    //   setActivePartData(part);
    // }, [part])
 
  const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;

  const navigate = useNavigate();
 

const CountTotalCoins = question => {
  let total_coins = 0;

  if (question && question.length > 0) {
      question.forEach(activity => {
          if (activity.fk_sub_id === '1') {
              total_coins += activity.english_question.reduce((acc, question) => acc + question.english_question_parts.length * 2, 0);
          } else if (activity.fk_sub_id === '2' || activity.fk_sub_id === '3') {
              total_coins += activity.question.reduce((acc, question) => acc + question.question_parts.length * 2, 0);
          }
      });
  }
 // setTotalCoins(total_coins);
  return total_coins;
};


// useEffect(useCallback(() => {

// if(data && data!=''){
//     setActivityData(data);
// }else{
//     setActivityData([]);
// }

  
//}, [data])


  const [selectedAnswer, setselectedAnswer] = useState(null);
  const [selectedAnswerText, setselectedAnswerText] = useState([]);

  const [nextQuestion, setnextQuestion] = useState(true);
  const [resultData, setresultData] = useState(null);
  const [IsSubmited, setIsSubmited] = useState(false);
  
 
    
    
    
    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_standard1, setmodal_standard1] = useState(false);

    const [isFlagReport, setFlagReport] = useState(false);
    const [isQuestionReport, setQuestionReport] = useState(false);
    
    const [selectedGroup, setselectedGroup] = useState(null);

    // // Initialize state for answerText with initial data
 const [ConfidentEM, setConfidentEM] = useState(0);
 const [ResonAnswerTextData, setResonAnswerText] = useState('');

    const [ActivePartData, setActivePartData] = useState([]);
    const [ActivityData1, setActivityData1] = useState(null);
 
    useEffect(() => {
      if(part && part!='' && data && data!=''){
        setActivityData1(data);
      setActivePartData(part);
      //setValuefield(false);
      setselectedAnswer(null);
      setselectedAnswerText([]);

      setnextQuestion(true);
      setresultData(null);
      setIsSubmited(false);

      setmodal_standard(false);
      setmodal_standard1(false);
      setFlagReport(false);
      setQuestionReport(false);
      setselectedGroup(null);

      setConfidentEM(0);
      setResonAnswerText('');
      }
    }, [part, data])
    
    function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
    }
    const tog_standard1 = () => {
      setmodal_standard1(!modal_standard1)
      }
    const optionGroup = [
    // {
    // label: "Select Subject",
    // options: [
    { label: "Spelling error / Typo", value: "Spelling error / Typo" },
    { label: "The solution is wrong", value: "The solution is wrong" },
    {
    label: "The question is not relevant to the topic",
    value: "The question is not relevant to the topic",
    },
    {
    label: "The question cannot be solved",
    value: "The question cannot be solved",
    },
    {
    label: "The question is incomplete",
    value: "The question is incomplete",
    },
    { label: "Others", value: "Others" },
    // ],
    // },
    ]
    const [ResonTextData, setResonText] = useState('');
    function textareachange(event) {
    const text = event.target.value;
   // if (count > 0) {
    setResonText(text);
   // }
    //settextcount(event.target.value.length)
    }
    


      const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
          reson: "",
          reson_text: "" 
        },
        validationSchema: Yup.object({
          reson: Yup.mixed().required("Select a reason is required."),
          reson_text: Yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Reason is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),        
        }),
        onSubmit: async values => {
          // console.log(values);
    
          if (authUser && authUser != "" && authUser != null) {

            setFlagReport(true);
              axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
              axios.post(BASE_URL+'studentSession/flagReport', {
                fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
                fk_lesson_activity_question_key : ActivityData1.pk_lesson_activity_question_key,
                fk_sub_id: ActivityData1.fk_sub_id,
                question_id: ActivityData1.fk_question_id,
                // reson_text: ResonTextData,
                // reson: selectedGroup
                ...values
              }).then(responseFlag => {
                //console.log(responseSessionEnd.data);
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                if (parseInt(responseFlag.data) === 1) {
                  tog_standard();
                  setFlagReport(false);
                  toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
                    autoClose: 3000, // 3 seconds in this example
                  });
                } else if(parseInt(responseFlag.data) === 2){
                  tog_standard();
                  setFlagReport(false);
                  toast.error("Already submitted a report on this question", {
                    autoClose: 3000, // 3 seconds in this example
                  });
                } else {
                  tog_standard();
                  setFlagReport(false);
                  toast.error("Failure! Unable to insert data. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                  });
                }
      
      
      
              })
                .catch(error => {
                  //console.log(error)
                  tog_standard();
                  setFlagReport(false);
                  if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
                    localStorage.clear();
                    navigate("/login");
                  }
                });
      
          
          }
          else {
            setFlagReport(false);
            navigate("/login");
          }
    
          //dispatch(loginUser(values, props.router.navigate));
        },
      })
      const tog_standard = () => {
        setmodal_standard(!modal_standard);
        validation.resetForm()
        setselectedGroup(null);
        setResonText('');
        }
      // function submitFlagReport(){
      //   if (authUser && authUser != "" && authUser != null) {

      //     setFlagReport(true);
      //       axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
      //       axios.post(BASE_URL+'studentSession/flagReport', {
      //         fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
      //         fk_lesson_activity_question_key : ActivityData1.pk_lesson_activity_question_key,
      //         fk_sub_id: ActivityData1.fk_sub_id,
      //         question_id: ActivityData1.fk_question_id,
      //         reson_text: ResonTextData,
      //         reson: selectedGroup
      //       }).then(responseFlag => {
      //         //console.log(responseSessionEnd.data);
      //         // Assuming the API response is an array of objects with 'id' and 'value' properties
      //         if (parseInt(responseFlag.data) === 1) {
      //           tog_standard();
      //           setFlagReport(false);
      //           toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
      //             autoClose: 3000, // 3 seconds in this example
      //           });
      //         } else {
      //           tog_standard();
      //           setFlagReport(false);
      //           toast.error("Failure! Unable to insert data. Try again later", {
      //             autoClose: 3000, // 3 seconds in this example
      //           });
      //         }
    
    
    
      //       })
      //         .catch(error => {
      //           //console.log(error)
      //           tog_standard();
      //           setFlagReport(false);
      //           if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
      //             localStorage.clear();
      //             navigate("/login");
      //           }
      //         });
    
        
      //   }
      //   else {
      //     setFlagReport(false);
      //     navigate("/login");
      //   }
    

      // }
      function submitQuestionReport(){
        if (authUser && authUser != "" && authUser != null) {

          setQuestionReport(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
            axios.post(BASE_URL+'studentSession/questionReport', {
              fk_lesson_activity_key: ActivityData1.fk_lesson_activity_key,
              fk_lesson_activity_question_key : ActivityData1.pk_lesson_activity_question_key,
              fk_sub_id: ActivityData1.fk_sub_id,
              question_id: ActivityData1.fk_question_id
            }).then(responseQus => {
              //console.log(responseSessionEnd.data);
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              if (parseInt(responseQus.data) === 1) {
                tog_standard1();
                setQuestionReport(false);
                toast.success("Thank you! Our Curriculum team will review and add 5 coins if the issue reported is correct.", {
                  autoClose: 3000, // 3 seconds in this example
                });
                
              } else {
                tog_standard1();
                setQuestionReport(false);
                toast.error("Failure! Unable to insert data. Try again later", {
                  autoClose: 3000, // 3 seconds in this example
                });
              }
    
    
    
            })
              .catch(error => {
                //console.log(error)
                tog_standard1();
                setQuestionReport(false);
                if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
                  localStorage.clear();
                  navigate("/login");
                }
              });
    
        
        }
        else {
          tog_standard1();
          setQuestionReport(false);
          navigate("/login");
        }
    

      }

//caregory 1
  const selectedAnsOption = (answer) =>{
    //console.log(answer);

    if(parseInt(ActivityData1.fk_sub_id) === 1){
      setselectedAnswer(answer.answer_id);
        let result = [{
          'part_id': answer.answer_diagnostic_id,
          'answer_id': answer.answer_id,
          'text': answer.answer_text
      }];
      setselectedAnswerText(result);
    }else{
      setselectedAnswer(answer.answer_id);
      let result = [{
        'part_id': answer.answer_diagnostic_id,
        'answer_id': answer.answer_id,
        'text': answer.answer_text
    }];
    setselectedAnswerText(result);
    }
    
  
  //  const jsonString = JSON.stringify(result).replace(/\"/g, '\\"');
   //console.log(result);
    
  
  }


//category 8


function textareaAnswerchange(event) {
const text = event.target.value;
if (text.trim().length === 0) {
  setResonAnswerText('');
}else{
  setResonAnswerText(text);
}

}

function Confidencechange(event) {
  setConfidentEM(event);
}

// function handletext(e, answer_id, part_id){
// //console.log(e)
// var answer_text = e.target.value;
// //CountAnswerQuestion(part_id,answer_id, e.target.value);
// setselectedAnswerText(prevAnswerText => {
//   if (!answer_text.trim()) {
//     // If text is empty or contains only whitespace, remove the corresponding entry
//     return prevAnswerText.filter(item => item.answer_id !== answer_id);
//   } else {
//     const existingAnswerIndex = prevAnswerText.findIndex((item) => item.answer_id === answer_id);

//     if (existingAnswerIndex !== -1) {
//       // If answer_id already exists, update the text
//       const updatedAnswerText = [...prevAnswerText];
//       updatedAnswerText[existingAnswerIndex] = { part_id: part_id, answer_id: answer_id, text: answer_text };
//       return updatedAnswerText;
//     } else {
//       // If answer_id does not exist, add the new answer
//       return [...prevAnswerText, { part_id: part_id, answer_id: answer_id, text: answer_text }];
//     }
//   }
// });
// }


  useEffect(() => {
   // console.log(responceData);


    if(responceData && responceData != null){
      setIsSubmited(false);
      setselectedAnswer(null);
      setresultData(responceData);
      // setConfidentEM(0);
      // setResonAnswerText('');
      let ansStatus = responceData.is_correct || null;
      //setresultData(responceData);
     // if(parseInt(ansStatus) === 1){ 
        //green color
        if(parseInt(ActivityData1.fk_sub_id) === 1){
          //english functionality correct answer start
          if (responceData.answerArray.length > 0) {
              //console.log(responceData.answerArray);
            // Create a copy of ActiveData
              const updatedData = [ ...ActivePartData ];

                // Update the copied data
                updatedData.forEach((quspart) => {
                    //console.log(quspart)
                    // quspart.english_question_answers.forEach((qusAns) => {
                      // Assign answerArray directly to StudentAnsAttempts
                      quspart.StudentAnsAttempts = responceData.answerArray;
                    // });
                  });
              // Update the state with the modified data
              setActivePartData(updatedData);
              
              setselectedAnswerText([]);
              if(parseInt(ActivityData1.is_current_tab_total_part) === parseInt(ActivityData1.is_current_tab_current_part)){
                setnextQuestion(false);
          
              }else{
               // window.location.reload();
               triggerFunction();
              }
                
          }
          //english functionality correct answer end
        }else{
          if (responceData.answerArray.length > 0) {
            //console.log(responceData.answerArray);
          // Create a copy of ActiveData
            const updatedData = [ ...ActivePartData ];

              // Update the copied data
              updatedData.forEach((quspart) => {
                  //console.log(quspart)
                  // quspart.english_question_answers.forEach((qusAns) => {
                    // Assign answerArray directly to StudentAnsAttempts
                    quspart.StudentAnsAttempts = responceData.answerArray;
                  // });
                });
            // Update the state with the modified data
            setActivePartData(updatedData);
            
            setselectedAnswerText([]);

            if(parseInt(ActivityData1.is_current_tab_total_part) === parseInt(ActivityData1.is_current_tab_current_part)){
              setnextQuestion(false);
        
            }else{
              //window.location.reload();
              triggerFunction();
            }
              
        }

        }
        

      // }else{
      //   //console.log(responceData.answerArray);
      //   //red color

      //   if(parseInt(ActivityData1.fk_sub_id) === 1){
      //       if(responceData.answerArray.length > 0){
      //       // console.log(responceData.answerArray);
      //       const updatedData = [ ...ActivePartData ];

      //       // Update the copied data
      //       updatedData.forEach((quspart) => {
      //           //console.log(quspart)
      //           // quspart.english_question_answers.forEach((qusAns) => {
      //             // Assign answerArray directly to StudentAnsAttempts
      //             quspart.StudentAnsAttempts = responceData.answerArray;
      //           // });
      //         });
          
      //       // Update the state with the modified data
      //       setActivePartData(updatedData);
      //       }

      //       if(responceData.attempCount === 2){

      //         setselectedAnswerText([]);

      //         if((responceData && responceData.LastQuestion === true) ||
      //         (ActivityData1.is_current_tab_current_part === ActivityData1.is_current_tab_total_part)){
      //           setnextQuestion(false);
      //         }else{
      //           window.location.reload();
      //         }
      //       }else{
      //         setselectedAnswerText([]);
      //       }

      //   }else{
      //     if(responceData.answerArray.length > 0){
      //       // console.log(responceData.answerArray);
      //       const updatedData = [ ...ActivePartData ];

      //       // Update the copied data
      //       updatedData.forEach((quspart) => {
      //           //console.log(quspart)
      //           // quspart.english_question_answers.forEach((qusAns) => {
      //             // Assign answerArray directly to StudentAnsAttempts
      //             quspart.StudentAnsAttempts = responceData.answerArray;
      //           // });
      //         });
          
      //       // Update the state with the modified data
      //       setActivePartData(updatedData);
      //       }

      //       if(responceData.attempCount === 2){

      //         setselectedAnswerText([]);

      //         if((responceData && responceData.LastQuestion === true) ||
      //         (ActivityData1.is_current_tab_current_part === ActivityData1.is_current_tab_total_part)){
      //           setnextQuestion(false);
      //         }else{
      //           window.location.reload();
      //         }

      //       }else{
      //         setselectedAnswerText([]);
      //       }
      //   }
      
      // }

  }


  }, [responceData])
  


//console.log(ActivityData1);
// console.log(responceData);
//console.log(ActivePartData);


// console.log(selectedAnswerText);
//redirect Link
const assessementSummary = () =>{

  if(ActivityData1 && ActivityData1.fk_lesson_activity_key && ActivityData1.fk_lesson_activity_key!=''){

    if(ActivityData1.fk_sub_id && ActivityData1.fk_sub_id!=''){
      
      navigate("/diagnostic-summary/"+ActivityData1.fk_lesson_activity_key+"?type="+ActivityData1.fk_sub_id+"&homework="+ActivityData1.IsHomework);

    }else{
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

  }else{
    toast.error("Sorry, Activity Key Missing", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
  
}

// console.log(selectedGroup);
// console.log(ResonTextData);
return (
  
    (ActivityData1 && ActivityData1.fk_sub_id && parseInt(ActivityData1.fk_sub_id) === 1) ? (
      
      ActivityData1.english_question.map(
        
        (ActivityData, index) => {
          if (index === 0) {
            if(ActivityData && ActivityData.diagnostic_category_id && parseInt(ActivityData.diagnostic_category_id) === 1){
              return(
               
              <div className="hw-list mt-4 pb-100">
                 {/* <ToastContainer autoClose={3000} /> */}
                 <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                    <ModalHeader toggle={tog_standard1}>

                    </ModalHeader>
                    <ModalBody>
                      <p className="text-center fw-bold text-dark mb-0 font-size-18">Report this question to your tutor
                        as being incorrectly marked?</p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button className="btn-cst-success-md" disabled={isFlagReport} onClick={()=>{submitQuestionReport()}}>
                        Yes
                      </Button>
                      <Button className="btn-cst-danger-md" onClick={()=>{tog_standard1()}}>
                        No
                      </Button>
                    </ModalFooter>
                  </Modal>

              <Modal isOpen={modal_standard} toggle={tog_standard}>
              <Form
                        className="mt-4"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action="#"
                      >
                        <ModalHeader toggle={tog_standard}>
                          Flag this Question
                        </ModalHeader>
                        <ModalBody>
                          <div className="flex-grow-1 mb-3 cs-rsm">
                            <Label>Select a Reason</Label>
                            <Select  name="reson" id="reson" className="cs-style bg-mutant" value={optionGroup.find(option =>
                                option.value &&
                                validation.values.reson &&
                                option.value.toString() == validation.values.reson.toString()
                              )} 
                              placeholder="Select"
                              onChange={(selectedOption) => {
                                const event = {
                                    target: {
                                        name: "reson",
                                        value: selectedOption["value"],
                                    },
                                };
                                validation.handleChange(event);
                            }}
                            onBlur={(event) => {
                              validation.handleBlur(event);
                            }}
                            // invalid={
                            //   validation.touched.reson &&
                            //   validation.errors.reson
                            //     ? true
                            //     : false
                            // }
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              />
                               {validation.touched.reson &&
                                validation.errors.reson ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson}</div>
                                ) : null}
                          </div>
                          <div className="flex-grow-1 cs-rsm">
                            <Label>Reason:</Label>
                            <Input style={{ height: "200px" }} type="textarea" name="reson_text" id="reson_text" 
                            // onChange={e=> {
                            // textareachange(e)
                            // }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reson_text || ""}
                            // invalid={
                            //   validation.touched.reson_text &&
                            //   validation.errors.reson_text
                            //     ? true
                            //     : false
                            // }
                            maxLength="225"
                            rows="3"
                            placeholder="Write your reason here..."
                            />
                            {validation.touched.reson_text &&
                                validation.errors.reson_text ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson_text}</div>
                                ) : null}
                          </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                          <Button type="submit" className="btn btn-danger cs-btn-danger" disabled={isFlagReport}>
                            Submit
                          </Button>
                        </ModalFooter>
                        </Form>
                      </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li className="justify-content-between">
                    <div className="d-flex align-items-start gap-4 justify-content-between">
                      <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.english_diagnostic_skill && ActivityData.english_diagnostic_skill.level_name ? 
                        ActivityData.english_diagnostic_skill.level_name +' /' : null}
                        
                        {ActivityData.english_diagnostic_skill && ActivityData.english_diagnostic_skill.strand_name ? 
                        ActivityData.english_diagnostic_skill.strand_name +' /' : null}

                        {ActivityData.english_diagnostic_skill && ActivityData.english_diagnostic_skill.topic_name ? 
                        ActivityData.english_diagnostic_skill.topic_name +' /' : null}

                        {ActivityData.english_diagnostic_skill && ActivityData.english_diagnostic_skill.sub_topic_name ? 
                        ActivityData.english_diagnostic_skill.sub_topic_name : null}
                    
                      </p>
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">#{ActivityData.diagnostic_id ? 
                        ActivityData.diagnostic_id : null}</p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      
                      </div>
                    </div>

                    <div className="mt-4" dangerouslySetInnerHTML={{
                        __html: ActivityData.diagnostic_text ? ActivityData.diagnostic_text.replace(/\[(\d+)Answer char=\d+\]/g, '').replace(/\[Answer char=\d+\]/g, '') : null,
                      }}>
                      </div>


                    <ul className="dg-selector width-controll list-unstyled mb-0">
                    { ActivePartData && ActivePartData.map(
                        (quspart, index) => {
                          //const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => stuAns.student_answer);

                          const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                                return JSON.parse(stuAns.student_answer);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    return null; // or handle the error in an appropriate way
                                }
                            }) || []; // Extracting session keys
                           
                            // Assuming stuAnswersarray is a nested array
                            const flattenedStuAnswers = [].concat(...stuAnswersarray);
                            //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                          return quspart.english_diagnostic_answers && quspart.english_diagnostic_answers.map(
                            (qusAns, index1) => {
                                let filteredStuAns = flattenedStuAnswers.filter(stuAns => ((parseInt(stuAns.part_id) === parseInt(qusAns.answer_diagnostic_id)) 
                                && (parseInt(stuAns.answer_id) === parseInt(qusAns.answer_id)))
                                ) || [];
                           
                              var status_color = 'selector-general cursor-pointer';
                              var border_color = 'border-start-secondary';
                              if(filteredStuAns.length > 0){
                                if(parseInt(qusAns.answer_is_correct) === 1){
                                  status_color = 'selector-success';
                                  border_color = 'border-start-success';
                                }else{
                                  status_color = 'selector-wrong ';
                                  border_color = 'border-start-danger';
                                }
                              }
                            //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                            return(<li className={selectedAnswer && selectedAnswer === qusAns.answer_id ? status_color+ ' active choose e_choose' : status_color+ ' choose e_choose'}  key={index1} onClick={() => selectedAnsOption(qusAns)}>
                              <div className="p-2 px-3 font-size-18">{index1 + 1}</div>
                              <div className={"p-2 dg-media "+{border_color}} dangerouslySetInnerHTML={{
                                    __html: qusAns.answer_text ? qusAns.answer_text : null,
                                  }}>
                                
                              </div>
                            </li>)
                            })
                            

                        })
                      }
                    
                    </ul>
                    {selectedAnswerText && selectedAnswerText!=null && selectedAnswerText.length > 0 ?   <div>
                    <h4 className="font-size-20 fw-bold mb-4 mt-3">
                        Why did you choose this option?
                      </h4>
                      <Input
                        style={{
                          height: "150px",
                          border: "1px solid #1a1a1a",
                          borderRadius: "4px",
                          fontSize: "18px",
                        }}
                        type="textarea"
                        value={ResonAnswerTextData}
                        id="textarea"
                        onChange={e => {
                          textareaAnswerchange(e)
                        }}
                        disabled ={!nextQuestion ? true : false}
                        maxLength="225"
                        rows="3"
                      />
                      </div> : null}

                      {ResonAnswerTextData && ResonAnswerTextData!=null && ResonAnswerTextData!='' ?    
                      <div>
                      <h4 className="font-size-20 fw-bold mb-4 mt-4">
                        How confident are you in your answer?
                      </h4>

                      <div className="emotion-wrapper pb-50">
                        <ul className="list-unstyled mb-0">
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(1)}>
                            <img className={ConfidentEM === 1 ? 'active-img' : ''} src={ConfidentEM === 1 ? ema1 : em1} alt="" />
                            {ConfidentEM === 1 ? <p className="text-center subject-float">I completely don’t know.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(2)}>
                            <img className={ConfidentEM === 2 ? 'active-img' : ''} src={ConfidentEM === 2 ? ema2 : em2} alt="" />
                            {ConfidentEM === 2 ? <p className="text-center subject-float">I'm not sure if this is correct.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(3)}>
                            <img className={ConfidentEM === 3 ? 'active-img' : ''} src={ConfidentEM === 3 ? ema3 : em3} alt="" />
                            {ConfidentEM === 3 ? <p className="text-center subject-float">I think I might be wrong.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(4)}>
                            <img className={ConfidentEM === 4 ? 'active-img' : ''} src={ConfidentEM === 4 ? ema4 : em4} alt="" />
                            {ConfidentEM === 4 ? <p className="text-center subject-float">I think this is the right answer.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(5)}>
                            <img className={ConfidentEM === 5 ? 'active-img' : ''} src={ConfidentEM === 5 ? ema5 : em5} alt="" />
                            {ConfidentEM === 5 ? <p className="text-center subject-float">This is definitely the right answer!</p> : null}
                          </li>
                        </ul>
                      </div>

                      </div> : null}

                    {/* {!nextQuestion ? <><h4 className="font-size-16 fw-bold mb-2 mt-3">
                      Solution
                    </h4>
                    <div className="e_feedBack" style={{
                            
                            border: "1px solid #1a1a1a",
                            borderRadius: "4px",
                            fontSize: "16px",
                          }} 
                    >
                      { ActivityData.english_diagnostic_answers
                        .filter(CorrAns => parseInt(CorrAns.answer_is_correct) === 1)
                        .map((qusAns, index1) => (
                          <div key={index1} dangerouslySetInnerHTML={{
                            __html: qusAns.answer_text,
                          }}></div>
                        ))}


                      </div></> : null } */}

                    <div className="text-end mt-3 mb-5">
                    {ConfidentEM && nextQuestion && ConfidentEM!=null && ConfidentEM!='' && ConfidentEM > 0 ? <Button className="btn btn-danger cs-btn-danger mt-1" disabled={IsSubmited} onClick={() => {triggerSubmitFunction([{Answer:selectedAnswerText, ResonTestAnswer: ResonAnswerTextData, Confident : ConfidentEM}]); setIsSubmited(true);}}>
                        Submit
                      </Button> : null }
                    </div>

                  </li>
                </ul>

                {!nextQuestion ? <div className="navigator-wrapper position-relative">
                  <div className="nv-dg-smile">
                    <img width={400} src={dsmile} alt="" />
                  </div>
                  <div className="navigator-nxt">
                    <p className="opacity-75">
                     {ActivityData && ActivityData.diagnostic_time_needed && ActivityData.diagnostic_time_needed!='' ? 'SUGGESTED TIME: '+ActivityData.diagnostic_time_needed : null}
                    </p>
                    <p className="font-size-16 d-flex align-items-center gap-2">
                      <img width={24} src={awicon} /> {resultData ? resultData.latestScore : 0} / {resultData && resultData.question_length ? resultData.question_length * 2 : 0} coins earned
                    </p>
                    <p className="opacity-75">{resultData ? parseInt(resultData.accuracyOfOtherStudents) : 0}% OF STUDENTS GOT IT RIGHT</p>
                    {resultData? !resultData.LastQuestion ?
                     <Button className="btn btn-danger cs-btn-danger mt-1" 
                     onClick={() => { //window.location.reload();
                      triggerFunction();
                      setnextQuestion(false);
                      setActivePartData([]);
                      setIsSubmited(false);
                      setresultData(null); }}>
                     Next Question
                   </Button> :
                    <Button className="btn btn-danger cs-btn-danger mt-1" onClick={()=> assessementSummary()}>
                    Go to summary
                  </Button> : null }
                   
                    { /* <span className="font-size-15 mt-3 text-dark fw-bold" onClick={tog_standard1} style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                      It was marked incorrectly
                        </span> */}
                    
                  </div>
                </div> : null }
              </div>)

            }else{
              return(<p className="text-center">No question</p>)
            }
          }
      })
    

    ): (ActivityData1 && ActivityData1.fk_sub_id && parseInt(ActivityData1.fk_sub_id) === 2) ? (

      ActivityData1.question.map(
        (ActivityData, index) => {
          if (index === 0) {
            if(ActivityData && parseInt(ActivityData.diagnostic_category_id) === 0){
              return(<div className="hw-list mt-4 pb-100">
                    {/* <ToastContainer autoClose={3000} /> */}
                 <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                    <ModalHeader toggle={tog_standard1}>

                    </ModalHeader>
                    <ModalBody>
                      <p className="text-center fw-bold text-dark mb-0 font-size-18">Report this question to your tutor
                        as being incorrectly marked?</p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button className="btn-cst-success-md" disabled={isFlagReport} onClick={()=>{submitQuestionReport()}}>
                        Yes
                      </Button>
                      <Button className="btn-cst-danger-md" onClick={()=>{tog_standard1()}}>
                        No
                      </Button>
                    </ModalFooter>
                  </Modal>

              <Modal isOpen={modal_standard} toggle={tog_standard}>
              <Form
                        className="mt-4"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action="#"
                      >
                        <ModalHeader toggle={tog_standard}>
                          Flag this Question
                        </ModalHeader>
                        <ModalBody>
                          <div className="flex-grow-1 mb-3 cs-rsm">
                            <Label>Select a Reason</Label>
                            <Select  name="reson" id="reson" className="cs-style bg-mutant" value={optionGroup.find(option =>
                                option.value &&
                                validation.values.reson &&
                                option.value.toString() == validation.values.reson.toString()
                              )} 
                              placeholder="Select"
                              onChange={(selectedOption) => {
                                const event = {
                                    target: {
                                        name: "reson",
                                        value: selectedOption["value"],
                                    },
                                };
                                validation.handleChange(event);
                            }}
                            onBlur={(event) => {
                              validation.handleBlur(event);
                            }}
                            // invalid={
                            //   validation.touched.reson &&
                            //   validation.errors.reson
                            //     ? true
                            //     : false
                            // }
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              />
                               {validation.touched.reson &&
                                validation.errors.reson ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson}</div>
                                ) : null}
                          </div>
                          <div className="flex-grow-1 cs-rsm">
                            <Label>Reason:</Label>
                            <Input style={{ height: "200px" }} type="textarea" name="reson_text" id="reson_text" 
                            // onChange={e=> {
                            // textareachange(e)
                            // }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reson_text || ""}
                            // invalid={
                            //   validation.touched.reson_text &&
                            //   validation.errors.reson_text
                            //     ? true
                            //     : false
                            // }
                            maxLength="225"
                            rows="3"
                            placeholder="Write your reason here..."
                            />
                            {validation.touched.reson_text &&
                                validation.errors.reson_text ? (
                                  <div className="invalid-feedback-all">{validation.errors.reson_text}</div>
                                ) : null}
                          </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                          <Button type="submit" className="btn btn-danger cs-btn-danger" disabled={isFlagReport}>
                            Submit
                          </Button>
                        </ModalFooter>
                        </Form>
                      </Modal>
                <ul className="list-unstyled mb-0 question_container">
                  <li className="justify-content-between">
                    <div className="d-flex align-items-start gap-4 justify-content-between">
                      <p className="mb-0 text-uppercase font-size-16">
                        {ActivityData.diagnostic_new_topic && ActivityData.diagnostic_new_topic.level_name ? 
                        ActivityData.diagnostic_new_topic.level_name +' /' : null}
                        
                        {ActivityData.diagnostic_new_topic && ActivityData.diagnostic_new_topic.topic_name ? 
                        ActivityData.diagnostic_new_topic.topic_name +' /' : null}

                        {ActivityData.diagnostic_new_topic && ActivityData.diagnostic_new_topic.sub_topics_name ? 
                        ActivityData.diagnostic_new_topic.sub_topics_name +' /' : null}

                      </p>
                      <div className="mb-0 d-flex align-items-center gap-2">
                        <p className="mb-0">#{ActivityData.diagnostic_id ? 
                        ActivityData.diagnostic_id : null}</p>
                        <span onClick={tog_standard}>
                          <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                        </span>
                      
                      </div>
                    </div>

                    <div className="mt-4" dangerouslySetInnerHTML={{
                        __html: ActivityData.diagnostic_text ? ActivityData.diagnostic_text.replace(/\[(\d+)Answer char=\d+\]/g, '').replace(/\[Answer char=\d+\]/g, '') : null,
                      }}>
                      </div>

                       

                      <ul className="dg-selector width-controll list-unstyled mb-0">
                    { ActivePartData && ActivePartData.map(
                        (quspart, index) => {
                          //const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => stuAns.student_answer);

                          const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => {
                            try {
                                return JSON.parse(stuAns.student_answer);
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    return null; // or handle the error in an appropriate way
                                }
                            }) || []; // Extracting session keys
                           
                            // Assuming stuAnswersarray is a nested array
                            const flattenedStuAnswers = [].concat(...stuAnswersarray);
                            //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                          return quspart.diagnostic_answers && quspart.diagnostic_answers.map(
                            (qusAns, index1) => {
                                let filteredStuAns = flattenedStuAnswers.filter(stuAns => ((parseInt(stuAns.part_id) === parseInt(qusAns.answer_diagnostic_id)) 
                                && (parseInt(stuAns.answer_id) === parseInt(qusAns.answer_id)))
                                ) || [];
                           
                              var status_color = 'selector-general cursor-pointer';
                              var border_color = 'border-start-secondary';
                              if(filteredStuAns.length > 0){
                                if(parseInt(qusAns.answer_is_correct) === 1){
                                  status_color = 'selector-success';
                                  border_color = 'border-start-success';
                                }else{
                                  status_color = 'selector-wrong ';
                                  border_color = 'border-start-danger';
                                }
                              }
                            //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                            return(<li className={selectedAnswer && selectedAnswer === qusAns.answer_id ? status_color+ ' active choose e_choose' : status_color+ ' choose e_choose'}  key={index1} onClick={() => selectedAnsOption(qusAns)}>
                              <div className="p-2 px-3 font-size-18">{index1 + 1}</div>
                              <div className={"p-2 dg-media "+{border_color}} dangerouslySetInnerHTML={{
                                    __html: qusAns.answer_text ? qusAns.answer_text : null,
                                  }}>
                                
                              </div>
                            </li>)
                            })
                            

                        })
                      }
                    
                    </ul>
                    
                   
                    {selectedAnswerText && selectedAnswerText!=null && selectedAnswerText.length > 0 ?   <div>
                    <h4 className="font-size-20 fw-bold mb-4 mt-3">
                        Why did you choose this option?
                      </h4>
                      <Input
                        style={{
                          height: "150px",
                          border: "1px solid #1a1a1a",
                          borderRadius: "4px",
                          fontSize: "18px",
                        }}
                        type="textarea"
                        value={ResonAnswerTextData}
                        id="textarea"
                        onChange={e => {
                          textareaAnswerchange(e)
                        }}
                        disabled ={!nextQuestion ? true : false}
                        maxLength="225"
                        rows="3"
                      />
                      </div> : null}

                      {ResonAnswerTextData && ResonAnswerTextData!=null && ResonAnswerTextData!='' ?    
                      <div>
                      <h4 className="font-size-20 fw-bold mb-4 mt-4">
                        How confident are you in your answer?
                      </h4>

                      <div className="emotion-wrapper pb-50">
                        <ul className="list-unstyled mb-0">
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(1)}>
                            <img className={ConfidentEM === 1 ? 'active-img' : ''} src={ConfidentEM === 1 ? ema1 : em1} alt="" />
                            {ConfidentEM === 1 ? <p className="text-center subject-float">I completely don’t know.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(2)}>
                            <img className={ConfidentEM === 2 ? 'active-img' : ''} src={ConfidentEM === 2 ? ema2 : em2} alt="" />
                            {ConfidentEM === 2 ? <p className="text-center subject-float">I'm not sure if this is correct.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(3)}>
                            <img className={ConfidentEM === 3 ? 'active-img' : ''} src={ConfidentEM === 3 ? ema3 : em3} alt="" />
                            {ConfidentEM === 3 ? <p className="text-center subject-float">I think I might be wrong.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(4)}>
                            <img className={ConfidentEM === 4 ? 'active-img' : ''} src={ConfidentEM === 4 ? ema4 : em4} alt="" />
                            {ConfidentEM === 4 ? <p className="text-center subject-float">I think this is the right answer.</p> : null}
                          </li>
                          <li className={!nextQuestion ? '' : "cursor-pointer"} onClick={()=> !nextQuestion ? null : Confidencechange(5)}>
                            <img className={ConfidentEM === 5 ? 'active-img' : ''} src={ConfidentEM === 5 ? ema5 : em5} alt="" />
                            {ConfidentEM === 5 ? <p className="text-center subject-float">This is definitely the right answer!</p> : null}
                          </li>
                        </ul>
                      </div>

                      </div> : null}

                    {/* {!nextQuestion ? <><h4 className="font-size-16 fw-bold mb-2 mt-3">
                      Solution
                    </h4>
                    <div className="e_feedBack" style={{
                            
                            border: "1px solid #1a1a1a",
                            borderRadius: "4px",
                            fontSize: "16px",
                          }} 
                    >
                      { ActivityData.diagnostic_answers
                        .filter(CorrAns => parseInt(CorrAns.answer_is_correct) === 1)
                        .map((qusAns, index1) => (
                          <div key={index1} dangerouslySetInnerHTML={{
                            __html: qusAns.answer_text,
                          }}></div>
                        ))}


                      </div></> : null } */}

                    <div className="text-end mt-3 mb-5">
                    {ConfidentEM && nextQuestion && ConfidentEM!=null && ConfidentEM!='' && ConfidentEM > 0 ? <Button className="btn btn-danger cs-btn-danger mt-1" disabled={IsSubmited} onClick={() => {triggerSubmitFunction([{Answer:selectedAnswerText, ResonTestAnswer: ResonAnswerTextData, Confident : ConfidentEM}]); setIsSubmited(true);}}>
                        Submit
                      </Button> : null }
                    </div>

                  </li>
                </ul>

                {!nextQuestion ? <div className="navigator-wrapper position-relative">
                  <div className="nv-dg-smile">
                    <img width={400} src={dsmile} alt="" />
                  </div>
                  <div className="navigator-nxt">
                    <p className="opacity-75">
                    {ActivityData && ActivityData.diagnostic_time_needed && ActivityData.diagnostic_time_needed!='' ? 'SUGGESTED TIME: '+ActivityData.diagnostic_time_needed : null}
              
                    </p>
                    <p className="font-size-16 d-flex align-items-center gap-2">
                      <img width={24} src={awicon} /> {resultData ? resultData.latestScore : 0} / {resultData && resultData.question_length ? resultData.question_length * 2 : 0} coins earned
                    </p>
                    <p className="opacity-75">{resultData ? parseInt(resultData.accuracyOfOtherStudents) : 0}% OF STUDENTS GOT IT RIGHT</p>
                    {resultData? !resultData.LastQuestion ?
                     <Button className="btn btn-danger cs-btn-danger mt-1" 
                     onClick={() => { //window.location.reload();
                      triggerFunction();
                      setnextQuestion(false);
                      setActivePartData([]);
                      setIsSubmited(false);
                      setresultData(null); }}>
                     Next Question
                   </Button> :
                    <Button className="btn btn-danger cs-btn-danger mt-1" onClick={()=> assessementSummary()}>
                    Go to summary
                  </Button> : null }
                   
                    {/* <span className="font-size-15 mt-3 text-dark fw-bold" onClick={tog_standard1} style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                      It was marked incorrectly
                    </span> */}
                    
                  </div>
                </div> : null }
              </div>)

            }else{
              return(<p className="text-center">No question</p>)
            }
          }
      })
      
    ): (<p>No Question</p>)
 
 
  
)
})


export default Comprehension